import { observable, action, computed } from "mobx";
import { RootStore } from "../store";
import {
	RestaurantUtils,
	CoreUtils,
	logger,
	FORMATS,
	getPreviousDate,
	getNextDate,
	CoordinatePointArray,
} from "@lib/common";
import autobind from "autobind-decorator";
import omit from "lodash/omit";
import { Moment } from "moment-timezone";
import { MapboxAPI } from "../../libs/mapbox";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { polygon, point } from "@turf/helpers";

export interface OrderConfigState extends T.Schema.Order.OrderConfig { }
export interface OrderConfigDisposable {
	delivery_address: string;
	delivery_modal: boolean;
	delivery_error: string;
	delivery_loading: boolean;
}

@autobind
export class OrderConfigStore {
	@observable s: OrderConfigState;
	@observable d: OrderConfigDisposable;
	store: RootStore;
	order_expiring_timer: any;
	order_expired_timer: any;

	constructor(store: RootStore, initialState?: OrderConfigState) {
		this.store = store;

		this.s = {
			...this.initialState(),
			...(initialState || {}),
		};

		this.d = observable({
			delivery_address: "",
			delivery_modal: false,
			delivery_loading: false,
			delivery_error: "",
		});

		if (
			typeof window !== "undefined" &&
			initialState &&
			initialState.table_id === "closed"
		) {
			(async () => {
				this.setService("");
				alert(store.intl.i18n.t("store.global.errors.dine_in_close"));
			})();
		}
	}

	initialState(): OrderConfigState {
		return {
			service: "",
			due: "",
			date: "",
			time: "",
			destination: "",
			destination_misc: "",
			lat: -1,
			lng: -1,
			distance: -1,
			driving_time: -1,
			zone: "",
			table: "",
			table_id: "",
			table_password: "",
			number_of_people: 0,
			confirmed: false,
			source: '',
			is_limit: false,
		};
	}

	// IF THE DUE DATE AND TIME HAS BEEN SURPASSED
	@computed get expiresAt() {
		const oc = this.s;
		if (oc.due === "later" && oc.date && oc.time) {
			const { order_offset } = this.orderService.times.conditions;
			const due = this.store.intl
				.momentFromFormat(
					`${oc.date} ${oc.time}`,
					FORMATS.moment.datetime
				)
				.valueOf();
			return due - 1000 * 60 * (order_offset || 0);
		}
		return -1;
	}

	@computed get isoDueTime() {
		const orderConfig = this.s;
		if (
			orderConfig.due === "later" &&
			orderConfig.date &&
			orderConfig.time
		) {
			return this.store.intl
				.momentFromFormat(
					`${orderConfig.date} ${orderConfig.time}`,
					FORMATS.moment.datetime
				)
				.toISOString();
		}
		return undefined;
	}

	// ORDERS FOR NOW CANNOT BE EXPIRED
	expired = () => {
		return this.expiresAt !== -1 && Date.now() > this.expiresAt;
	};

	clearTimers = () => {
		clearTimeout(this.order_expiring_timer);
		clearTimeout(this.order_expired_timer);
	};

	@computed get orderService() {
		const oc = this.s;
		const { restaurant } = this.store;
		return restaurant.settings.services[
			oc.service as T.Schema.Restaurant.Services.RestaurantServiceTypes
		];
	}

	@computed get orderDays() {
		logger.info("GET DAYS");
		const service = this.orderService;
		const { restaurant } = this.store;
		const opening_hours =
			service.hours && service.hours?.length > 0
				? service.hours
				: restaurant.location.opening_hours;
		const conditions = service.times.conditions;
		const max_days = conditions.max_days;
		const open_days =
			RestaurantUtils.opening_hours.getOpenDays(opening_hours);
		const days = [];
		const start = this.store.intl.momentNow();
		const { intl } = this.store;
		for (let day = 0; day < max_days; day++) {
			const date = start.clone().add(day, "days");
			const weekday = date.clone().locale("en").format("dddd");
			const dateFormatted = date
				.clone()
				.locale("en")
				.format(FORMATS.moment.date);
			const special_hours = (
				restaurant.location.special_hours || []
			).filter((h) => h.date === dateFormatted);

			let closed = open_days.indexOf(weekday as T.DaysOfWeek) === -1;
			let formattedDate = intl.i18n.t("dateFromTimestamp", {
				value: date.valueOf(),
			}); // date.format(FORMATS.localized.date_short);

			if (special_hours.length > 0) {
				closed = !special_hours[0].opened;
			}

			if (day === 0) {
				formattedDate += ` ${intl.i18n.t(
					"store.modals.order.config.datetime.date_today"
				)}`;
				if (!closed) {
					const todayTimes = opening_hours.filter(
						(slot) => slot.day === weekday
					);
					const lastSlot =
						special_hours.length > 0
							? special_hours[special_hours.length - 1]
							: todayTimes[todayTimes.length - 1];

					if (lastSlot && !lastSlot.h24) {
						const lastAvailableTime = this.store.intl
							.momentFromFormat(
								`${dateFormatted} ${lastSlot.close}`,
								FORMATS.moment.datetime
							)
							.subtract(conditions.last_order_offset, "minutes");
						if (
							this.store.intl
								.momentFromMillis(Date.now())
								.isAfter(lastAvailableTime)
						) {
							closed = true;
						}
					}
				}
			}

			if (closed) {
				formattedDate += ` ${intl.i18n.t(
					"store.modals.order.config.datetime.date_closed"
				)}`;
			}

			days.push({
				label: formattedDate,
				sublabel: date.locale(intl.s.lng).format("dddd"),
				value: date.locale("en").format(FORMATS.moment.date),
				disabled: closed,
			});
		}
		return days;
	}

	orderTimes = async (dateSelected: string) => {
		const { restaurant, intl } = this.store;
		const date = this.s.date;
		if (!date) return [];

		const service = this.orderService;
		const opening_hours =
			service.hours && service.hours?.length > 0
				? service.hours
				: restaurant.location.opening_hours;
		const {
			order_offset,
			first_order_offset,
			last_order_offset,
			time_interval,
			limit_order_per_segment
		} = service.times.conditions;
		const isAutoConfirmed = service.status.auto.confirmed && service.status.times.confirmed === 0;

		const current = intl.momentNow().second(0);
		const selected = intl.momentFromFormat(date, FORMATS.moment.date);
		const selectedWeekday = selected.clone().locale("en").format("dddd");

		const normal_hours = opening_hours.filter(
			(h) => h.day === selectedWeekday
		);
		const special_hours = (restaurant.location.special_hours || []).filter(
			(h) => h.date === date
		);
		const isSpecialHours = special_hours.length > 0;
		const hours = isSpecialHours ? special_hours : normal_hours;

		let times = [];

		let timeSlotCount = 0;

		for (const slot of hours) {
			timeSlotCount++;

			// @ts-ignore
			const dateString = isSpecialHours ? slot.date : "";
			// @ts-ignore
			const opened = isSpecialHours ? slot.opened === true : true;
			if (!opened) {
				continue;
			}

			if (timeSlotCount > 1) {
				times.push({
					label: intl.i18n.t(
						"store.modals.order.config.datetime.time_slot_closed"
					),
					sublabel: "",
					value: "",
					disabled: true,
				});
			}

			let continuesFromPreviousDay = false;
			if (slot.open === "00:00" || slot.h24) {
				let previousDayTimes = [];
				if (dateString) {
					const previousDate = getPreviousDate(dateString);
					previousDayTimes = (
						restaurant.location.special_hours || []
					).filter((s) => s.date === previousDate);
				} else {
					const previousWeekday = CoreUtils.dates.getPreviousWeekday(
						slot.day
					);
					previousDayTimes = opening_hours.filter(
						(s) => s.day === previousWeekday
					);
				}

				for (const s of previousDayTimes) {
					if (s.h24 || s.close === "23:59") {
						continuesFromPreviousDay = true;
						break;
					}
				}
			}

			let continuesToNextDay = false;
			if (slot.close === "23:59" || slot.h24) {
				let nextDayTimes = [];
				if (dateString) {
					const nextDate = getNextDate(dateString);
					nextDayTimes = (
						restaurant.location.special_hours || []
					).filter((s) => s.date === nextDate);
				} else {
					const nextWeekday = CoreUtils.dates.getNextWeekday(
						slot.day
					);
					nextDayTimes = opening_hours.filter(
						(s) => s.day === nextWeekday
					);
				}

				for (const s of nextDayTimes) {
					if (s.h24 || s.open === "00:00") {
						continuesToNextDay = true;
						break;
					}
				}
			}

			let open = this.store.intl
				.momentFromFormat(
					`${date} ${slot.h24 ? "00:00" : slot.open}`,
					FORMATS.moment.datetime
				)
				.add(first_order_offset || order_offset, "minutes")
				.second(0);
			let close = this.store.intl
				.momentFromFormat(
					`${date} ${slot.h24 ? "23:59" : slot.close}`,
					FORMATS.moment.datetime
				)
				.subtract(last_order_offset, "minutes")
				.second(59);

			// IF CONTINUOUS, REMOVE OFFSETS
			if (continuesFromPreviousDay) {
				open = this.store.intl
					.momentFromFormat(
						`${date} ${"00:00"}`,
						FORMATS.moment.datetime
					)
					.startOf("day");
			}

			if (continuesToNextDay) {
				close = this.store.intl
					.momentFromFormat(`${date} 23:59`, FORMATS.moment.datetime)
					.endOf("day");
			}

			let start: Moment | null = null;
			const end = close;

			console.log("OPEN", open.format(FORMATS.moment.datetime));
			console.log("CLOSE", close.format(FORMATS.moment.datetime));

			if (current.isBefore(open)) {
				// IF CURRENT BEFORE OPEN
				console.log("IS BEFORE");
				start = open;
				const timeTillOpen = open.diff(current, "minute");
				if (timeTillOpen < order_offset * 2) {
					start = current.clone().add(order_offset * 2, "minute");
				}
			} else if (current.isAfter(open) && current.isBefore(close)) {
				// IF CURRENT BETWEEN OPEN AND CLOSE
				console.log("IS BETWEEN");
				start = current.clone().add(order_offset * 2, "minutes");
				// IF ADDING ORDER OFFSET TAKES YOU PAST CLOSE TIME, DON'T ADD IT ON
				if (start.isAfter(close)) {
					// CLOSING SOON, ONLY IMMEDIATE ORDERS
					start = null; // current.clone();
				}
			}

			if (start !== null) {
				// ROUND THE START TIME
				while (start.minute() % time_interval !== 0) {
					start = start.clone().add(1, "minutes");
				}

				let interval = start.clone();

				while (interval.isSameOrBefore(end)) {
					times.push({
						label: intl.i18n.t("timeFromTimestamp", {
							value: interval.valueOf(),
						}), // interval.format(FORMATS.localized.time),
						value: interval
							.locale("en")
							.format(FORMATS.moment.time),
					});
					interval = interval.clone().add(time_interval, "minutes");
				}
			}
		}
		if (isAutoConfirmed && time_interval && limit_order_per_segment) {
			const orderCounts = await this.store.api.getOrderCountByService({
				service: this.s.service,
				timezone: this.store.restaurant.settings.region.timezone,
			})
			for (const data of orderCounts.data) {
				if (data.value >= limit_order_per_segment) {
					const timeIndex: number = times.findIndex((t) => dateSelected + " " + t.value === data.time);
					if (timeIndex !== -1) {
						// Update the disabled property
						times[timeIndex-1] = {
							...times[timeIndex-1],
							disabled: true,
						};
					}
				}
			}
		}
		return times;
	};

	@action clearDeliveryConfigs = () => {
		this.updateOrRemove({
			delivery_provider: undefined,

			postmates_quote_id: undefined,
			postmates_quote_amount: undefined,

			lalamove_quote_amount: undefined,
			lalamove_quote_currency: undefined,
			lalamove_quote_payload: undefined,
			lalamove_share_link: undefined,
			lalamove_market: undefined,
			lalamove_service_type: undefined,

			uber_delivery_error: undefined,
			uber_delivery_payload: undefined,
			uber_duration: undefined,
			uber_error: undefined,
			uber_latest_event_timestamp: undefined,
			uber_market: undefined,
			uber_order_eta: undefined,
			uber_order_id: undefined,
			uber_order_pickup_deadline: undefined,
			uber_order_pickup_eta: undefined,
			uber_order_pickup_ready: undefined,
			uber_order_status: undefined,
			uber_previous_quote_amount: undefined,
			uber_previous_total_fee: undefined,
			uber_quotation_id: undefined,
			uber_quote_amount: undefined,
			uber_quote_currency: undefined,
			uber_share_link: undefined,
			uber_quote_expire: undefined,
			uber_quote_payload: undefined,
			uber_service_type: undefined,
			uber_total_fee: undefined
		
		});
	};

	@action setService = (service: T.Schema.Order.OrderServices | "") => {
		if (this.store.cart) {
			this.store.cart.clear();
		}
		this.clearTimers();
		this.update({
			...this.initialState(),
			service: service,
		});
		this.updateD({
			delivery_address: '',
			delivery_modal: false,
			delivery_error: '',
			delivery_loading: false,
		})
	};

	@action setDestination = (data: T.Schema.Order.OrderConfigDelivery) => {
		const reset = omit(this.initialState(), ["service"]);
		this.update({
			...reset,
			...data,
		});
		// this.store.cart.clear();
	};

	@action setDue = (value: T.Schema.Order.OrderConfig["due"]) => {
		const reset = omit(this.initialState(), [
			"service",
			"destination",
			"destination_misc",
			"lat",
			"lng",
			"distance",
			"driving_time",
			"zone",
		]);
		// logger.info("SET DUE", reset);
		this.update({
			...reset,
			due: value,
		});
		// this.store.cart.clear();
	};

	@action setDate = (value: string) => {
		const reset = omit(this.initialState(), [
			"service",
			"destination",
			"destination_misc",
			"lat",
			"lng",
			"distance",
			"driving_time",
			"zone",
			"due",
		]);
		this.update({
			...reset,
			date: value,
		});
		// this.store.cart.clear();
	};

	@action setTime = (value: string) => {
		const reset = omit(this.initialState(), [
			"service",
			"destination",
			"destination_misc",
			"lat",
			"lng",
			"distance",
			"driving_time",
			"zone",
			"due",
			"date",
		]);
		this.update({
			...reset,
			time: value,
			timestamp: this.store.intl
				.momentFromFormat(
					`${this.s.date} ${value}`,
					FORMATS.moment.datetime
				)
				.valueOf(),
		});
		// this.store.cart.clear();
	};

	@action setTable = (_id: string, name: string) => {
		const reset = omit(this.initialState(), [
			"service",
			"due",
			"date",
			"time",
			"timestamp",
		]);
		this.update({
			...reset,
			table: name,
			table_id: _id,
		});
		// this.store.cart.clear();
	};

	@action setTablePassword = (value: string) => {
		this.s.table_password = value;
		this.s.confirmed = false;
	};

	@action setNumberOfPeople = (value: number) => {
		const reset = omit(this.initialState(), [
			"service",
			"due",
			"date",
			"time",
			"timestamp",
		]);
		this.update({
			...reset,
			number_of_people: value,
		});
		// this.store.cart.clear();
	};

	@action setConfirmed = async () => {
				const oc = this.s;
		const { order_offset } = this.orderService.times.conditions;

		// SET EXPIRY TIMERS FOR LATER ORDERS
		if (oc.date && oc.time) {
			this.clearTimers();

			const now = Date.now();

			const due = this.store.intl
				.momentFromFormat(
					`${oc.date} ${oc.time}`,
					FORMATS.moment.datetime
				)
				.valueOf();

			const expiresAt = due - 1000 * 60 * (order_offset || 0) || 0;

			// const warningAt = (expiresAt - (1000 * 60 * 10)) || 0;
			// const warningIn = warningAt - now;

			const expiryIn = expiresAt - now;
			const expiryInMinutes = expiryIn / (1000 * 60);

			console.log("EXPIRY", expiryIn, expiryInMinutes);

			/*
				this.order_expiring_timer = setTimeout(() => {
				this.store.modal.show("order-expiry-warning");
				}, warningIn || 0);
			*/

			// TO PREVENT INTEGER OVERFLOW
			if (expiryInMinutes < 3000) {
				this.order_expired_timer = setTimeout(() => {
					this.store.modal.show("order-expired-warning");

					this.store.order_config.update({
						due: "now",
						date: "",
						time: "",
					});
					// this.store.cart.clear();
					// this.setService("");
				}, expiryIn || 0);
			}
		}

		this.s.confirmed = true;

		if (
			this.store.customer.s.item &&
			this.store.order_history.s.items.length > 0
		) {
			this.store.modal.show("order-history");
		} else {
			this.store.modal.hide("order-config");
		}

		this.store.cart.clear();

		// AUTO APPLY PROMOS TO CART
		await this.store.cart.promoCodeApply();
	};

	@action setIsLimit = (isLimit: boolean) => {
		this.store.order_config.update({ is_limit: isLimit });
	}

	getIsLimit = () => {
		return this.store.order_config.s.is_limit;
	}

  isFromQR = (isFromQR: boolean) => {
    this.store.order_config.update({ is_from_qr_code: isFromQR });
  }

	@action update = (data: Partial<OrderConfigState>) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof OrderConfigState];
				if (value !== undefined) {
					// @ts-ignore
					this.s[key as keyof OrderConfigState] = value;
				}
			}
		}
	};

	@action updateD = (data: Partial<OrderConfigDisposable>) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof OrderConfigDisposable];
				if (value !== undefined) {
					// @ts-ignore
					this.d[key as keyof OrderConfigDisposable] = value;
				}
			}
		}
	};

	@action updateOrRemove = (data: Partial<OrderConfigState>) => {
		const keys: [keyof OrderConfigState] = Object.keys(data) as [
			keyof OrderConfigState
		];
		keys.forEach((key) => {
			const value = data[key];
			if (typeof value === "undefined") {
				delete this.s[key];
			} else {
				(this.s[key] as OrderConfigState[typeof key]) = value;
			}
		});
	};

	validate_delivery_details = async (destinationCords: {
		lat: number;
		lng: number;
	}) => {
		const { restaurant } = this.store;
		const service = restaurant.settings.services.delivery;
		const l = restaurant.location;

		const zones = this.store.serviceDeliveryZones;

		if (l.map_data.type !== "google_maps" && l.map_data.type !== "osm") {
			throw new Error(`Trying to use google maps when custom address`);
		}

		const from: CoordinatePointArray = [l.map_data.lng, l.map_data.lat];
		const to: CoordinatePointArray = [
			destinationCords.lng,
			destinationCords.lat,
		];

		let exclude = "ferry";
		if (service.avoid_highways) {
			exclude = "motorway";
		}
		if (service.avoid_tolls) {
			exclude = "toll";
		}

		const result = await MapboxAPI.directions(
			"mapbox/driving-traffic",
			from,
			to,
			{
				exclude: exclude,
				overview: "false",
			}
		);

		if (result.code !== "Ok") {
			return {
				valid: false,
				distance: -1,
				driving_time: -1,
				zoneMatch: undefined,
			};
		}

		let zoneMatch;
		let valid = false;
		let { distance, duration } = result.routes[0];

		const { conditions, default_delivery_provider } = service;
		const max_distance =
			this.store.restaurant.settings.region.kmmile === "KM"
				? (conditions.max_distance || 0) * 1000
				: (conditions.max_distance || 0) * 1609.34;
		const max_driving_time = (conditions.max_driving_time || 0) * 60;
    const ranges = RestaurantUtils.settings.getDeliverySettingsByProvider(restaurant, this.store.reseller, default_delivery_provider);
    const max_delivery_range =( default_delivery_provider !== 'restaurant' && ranges.length === 0) ? max_distance : (ranges[ranges.length - 1]?.up_to || 0) * (restaurant.settings.region.kmmile === 'KM' ? 1000 : 1609.34);
		if (zones.length > 0) {
			const destinationPoint = point(to);
			for (const zone of zones) {
				const zonePolygon = polygon([
					zone.polygon.map((p) => [p.lng, p.lat]),
				]);
				const inZone = booleanPointInPolygon(
					destinationPoint,
					zonePolygon
				);
				if (inZone) {
					zoneMatch = zone;
					valid = true;
					break;
				}
			}
		} else {
			valid =
			(max_delivery_range === 0 && max_distance === 0 && max_driving_time === 0) ||
			(max_distance === 0 && duration <= max_driving_time) ||
			(max_driving_time === 0 && distance <= Math.min(max_distance, max_delivery_range)) ||
			(max_distance === 0 && max_driving_time === 0 && distance <= max_delivery_range) ||
			(distance <= max_delivery_range && distance <= max_distance && duration <= max_driving_time);
		}

		logger.info("MAX DISTANCE", max_distance);
		logger.info("MAX DRIVING", max_driving_time);
		logger.info("DISTANCE", distance);
		logger.info("DRIVING", duration);
		logger.info("ZONE", zoneMatch);
		logger.info("VALID", valid);

		// console.log(`MAX DISTANCE: ${max_distance}`);
		// console.log(`MAX DRIVING: ${max_driving_time}`);
		// console.log(`DISTANCE: ${distance}`);
		// console.log(`DRIVING: ${duration}`);
		// console.log(`ZONE: ${zoneMatch}`);
		// console.log(`VALID: ${valid}`);
		// logger.info("GM", distanceMatrixResults);

		return { valid, zoneMatch, distance, driving_time: duration };
	};

  is_area_serviceable = async ({
     address,
     zip_code
   }: {
    address?: string,
    zip_code?: string
  }) => {
    const { restaurant } = this.store;

    const res = await this.store.api.get_serviceable_zones({
      restaurantId: restaurant._id,
      address,
      zip_code
    })

    if (res.outcome === 1) {
      return false
    } else {
      return res.is_serviceable
    }
  }
}
