import { config as commonConfig } from "../config";
import { nanoid } from "nanoid";
import { API } from "./api";
import { logger } from "@lib/common";
import { ProgressViewIOSBase } from "react-native";

export const config = {

	...commonConfig,

	port: parseInt((process.env.PORT || ""), 10) || 3000,

	build: process.env.BUILD || nanoid(),

	resellers: {
		"cloudwaitress.com": "cloudwaitress",
		"cloudwaitress-test.com": "cloudwaitress-test",
		"booknorder-test.com": "booknorder-test",
		"booknorder.co.nz": "booknorder",
		"orderbox.com.au": "orderbox",
		"grabet.com": "grabet",
		"quickorder.com.au": "quickorder",
		"airwaiters.com": "airwaiters",
		"bestonlinemenus.com": "bestonlinemenus",
	} as T.ObjectString,

	services: {
		api: {
			url: (
				commonConfig.isTest ? "http://localhost:3010" :
					commonConfig.isStaging ? "https://cw-dev-api.cloudwaitress-test.xyz" :
						"http://dataservice"
			),
			//This private key is also used in the gravity payment
			private_key: process.env.API_PRIVATE_KEY || "0411ca58-50e1-4a7f-b8f7-6d03d419d024",
		},
		sentry: {
			dsn: process.env.SENTRY_DSN || "",
		},
		google: {
			recaptcha_enterprise: {
				enabled: process.env.RECAPTCHA_ENTERPRISE_ENABLED || "true",
				site_key: process.env.RECAPTCHA_ENTERPRISE_SITE_KEY || "6LftLlchAAAAADuqwP9LNFzKN7Bfrh6WVtB9B-B6",
			}
		},
		s3: {
			bucket: commonConfig.isStaging ? "ordering-platform-dev" : 'ordering-platform',
		}
	},
};

async function getResellerDomains() {
	try {
		const res = await API.reseller_domains();
		if (res.outcome)
			throw new Error(res.message);
		config.resellers = res.list;
		console.log("RESELLERS", config.resellers);
	}
	catch (e) {
		logger.captureException(e);
	}
}

getResellerDomains();

setInterval(getResellerDomains, (1000 * 60 * 10));
