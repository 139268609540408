import * as React from 'react';
import autobind from 'autobind-decorator';
import { observable, action, computed, toJS } from 'mobx';
import { nanoid } from 'nanoid';
import axios from 'axios';
import Big from 'big.js';
import { isNil, isEmpty, omit } from 'lodash';
import { RootStore } from '../store';
import { config } from '../../../config';
import {
  logger,
  RestaurantUtils,
  DataCurrencies,
  CoreUtils,
  cloneDeepSafe,
  Untrusive,
  roundedNum,
  getCurrencyPrecision,
} from '@lib/common';
import Cookies from 'js-cookie';
import { doOrderValidation, validateTerms, validateCustomCheckoutFields, isCustomerInfoRequired, validateCustomerInformation, validateCustomerSimple } from './checkout/order-validation';
import * as Ably from 'ably';
import { createBamboraNAPaymentRequest, createBamboraNAPaymentRequest_v2 } from './checkout/payment/bambora_na';
import { genRecaptchaToken } from '../../libs/grecaptcha';

export interface CheckoutState {
  name: string;
  email: string;
  phone: string;
  phone_local_format: string;
  phone_e164_format: string;
  zip: string;
  notes: string;
  checkout_fields: T.Schema.CustomFieldValue[];
  payment: T.Schema.Restaurant.Payments.RestaurantPaymentTypes | '';
  subPayment: T.Schema.Restaurant.Payments.RestaurantSubPaymentTypesBase | undefined;
  selectedPayment: T.Schema.Restaurant.Payments.RestaurantPaymentTypes | '';
  ath_movil_status: boolean;
  terms: boolean;
  save_guest: boolean;
  paypal_show: boolean;
  paypal_error: string;
  card_number: string;
  card_expiry: string;
  card_cvv: string;
  card_token: string;
  card_error: string;
  address_field: string; // CATCH
  cardholder_name: string;
  billing_address_street: string;
  billing_address_city: string;
  postal_code: string;
  loading: boolean;
  error: string; // this stores just the translation codes, not messages
  error_details: string; // append extra details e.g. decline_code
  using_applepay: boolean;
  using_googlepay: boolean;
  using_stripe_digital_wallet: boolean;
  using_razor_pay: boolean;
  using_bancontact: boolean;
  using_bambora_na: boolean;
  using_stripe_connect: 'NOT CHECKED' | 'NOT CONNECT' | 'CONNECT';
  stripe_loading_form: boolean;
  stripe_connect_payment_intent_id: string;
  stripe_connect_payment_intent_client_secret: string;
  stripe_digital_wallet_payment_intent_id: string;
  stripe_digital_wallet_payment_client_secret: string;
  stripe_bancontact_payment_intent_id: string;
  stripe_bancontact_payment_intent_client_secret: string;
  stripe_paymentIntent_id?: string;
  authorized_card_not_verified: boolean;
}

@autobind
export class CheckoutStore {
  @observable s: CheckoutState;
  store: RootStore;
  ably?: Ably.Realtime;
  channel?: Ably.Types.RealtimeChannelCallbacks;
  @observable ablyState: Ably.Types.ConnectionState = 'initialized';

  constructor(store: RootStore, initialState?: CheckoutState) {
    this.store = store;
    this.s = initialState || this.initialState();
  }

  initialState(): CheckoutState {
    return {
      name: '',
      email: '',
      zip: '',
      phone: '',
      phone_local_format: '',
      phone_e164_format: '',
      notes: '',
      checkout_fields: [],
      payment: '',
      subPayment: undefined,
      selectedPayment: '',
      terms: false,
      save_guest: false,
      paypal_show: false,
      paypal_error: '',
      address_field: '',
      cardholder_name: '',
      billing_address_street: '',
      billing_address_city: '',
      postal_code: '',
      card_number: '',
      card_expiry: '',
      card_cvv: '',
      card_token: '',
      card_error: '',
      loading: false,
      error: '',
      error_details: '',
      ath_movil_status: false,
      using_applepay: false,
      using_googlepay: false,
      using_stripe_digital_wallet: false,
      using_razor_pay: false,
      using_bancontact: false,
      using_bambora_na: false,
      using_stripe_connect: 'NOT CHECKED',
      stripe_loading_form: false,
      stripe_connect_payment_intent_id: '',
      stripe_connect_payment_intent_client_secret: '',
      stripe_digital_wallet_payment_intent_id: '',
      stripe_digital_wallet_payment_client_secret: '',
      stripe_bancontact_payment_intent_id: '',
      stripe_bancontact_payment_intent_client_secret: '',
      stripe_paymentIntent_id: '',
      authorized_card_not_verified: true,
    };
  }

  logStep = (message: string) => {
    return this.logDetail(
      this.s.name,
      this.s.email,
      this.store.order.getGeneratedOrderId() || '[Order ID Not Available]',
      this.s.payment,
      this.s.payment === 'stripe' || this.s.payment === 'stripe_digital_wallet'
        ? this.s.using_stripe_connect
        : 'NOT CONNECT',
      message
    );
  };

  logDetail = async (
    customer_email: string,
    customer_name: string,
    order_id: string,
    payment_method: string,
    connect: string,
    message: string
  ) => {
    const messageLog = `CHECKOUT --- ${customer_email} --- ${customer_name} --- ${order_id} --- ${payment_method} --- ${connect} --- ${message}`
    console.log(messageLog);

    try {
      if (config.allow_send_fe_logs) {
        return this.store.api.createLogStore({
          message: messageLog,
          orderId: order_id,
          sessionId: Cookies.get('cw.stripe.payment.session'),
        });
      }
    } catch (error) {
      console.log('Error invoking log create', error);
      Promise.reject(error);
    }
  };

  @action resetPayments = () => {
    this.update({
      stripe_connect_payment_intent_client_secret: '',
      stripe_connect_payment_intent_id: '',
      stripe_digital_wallet_payment_intent_id: '',
      stripe_digital_wallet_payment_client_secret: '',
      stripe_bancontact_payment_intent_id: '',
      stripe_bancontact_payment_intent_client_secret: '',
      stripe_paymentIntent_id: '',
      stripe_loading_form: false,
      subPayment: undefined,
    });

    Cookies.remove('cw.stripe.payment.session');
  };

  @computed get paymentMethodsList() {
    const { payments } = this.store.restaurant.settings;
    const oc = this.store.order_config.s;
    const total = this.store.cart.total;
    const methods = Object.keys(payments).map(key => {
      const payment = payments[key];

      if (!payment) return null;

      let valid = true;

      if (!payment.enabled) {
        valid = false;
      }

      if (
        valid &&
        payment &&
        payment.services &&
        payment.services.length !== 0 &&
        payment.services.indexOf(oc.service as T.Schema.Restaurant.Services.RestaurantServiceTypes) === -1
      ) {
        valid = false;
      }

      if (valid && payment.max_order && Big(total).gte(payment.max_order)) {
        valid = false;
      }

      return valid ? key : null;
    });
    return methods.filter(m => m !== null) as T.Schema.Restaurant.Payments.RestaurantPaymentTypes[];
  }

  @computed get requiredCustomerInfo() {
    return isCustomerInfoRequired({
      restaurant: this.store.restaurant,
      checkout: this.s,
      store: this.store,
    });
  }

  @computed get paymentMethods() {
    const { store } = this;
    const { payments } = store.restaurant.settings;
    const activeMethods = this.paymentMethodsList;
    return activeMethods.map(method => {
      return {
        key: method,
        ...payments[method]!,
      };
    });
  }

  @computed get currency() {
    const r = this.store.restaurant;
    const checkout = this.s;

    const { region } = r.settings;

    if (!checkout.payment || checkout.payment === 'cash' || checkout.payment === 'card') {
      return {
        store: region.currency.code,
        payment: region.currency.code,
        isDifferent: false,
      };
    }

    const method = r.settings.payments[checkout.payment];
    let paymentCurrency = region.currency.code;

    if (!method) {
      return {
        store: region.currency.code,
        payment: region.currency.code,
        isDifferent: false,
      };
    }

    if (method.currency && method.currency !== region.currency.code) {
      paymentCurrency = method.currency;
    }

    return {
      store: region.currency.code,
      payment: paymentCurrency,
      isDifferent:
        typeof method.currency !== 'undefined' && method.currency !== '' && method.currency !== region.currency.code,
    };
  }

  @computed get delivery_provider() {
    return RestaurantUtils.settings.getDefaultDeliveryProvider(this.store.restaurant);
  }

  @action async computePaymentDetails() {
    const api = this.store.api;
    const r = this.store.restaurant;
    const cart = this.store.cart;
    const { region } = r.settings;

    const paymentCurrency = this.currency.payment;

    let paymentTotal = cart.total;
    let currencyStep = this.store.intl.s.currency.step;
    let paymentCurrencyStep = paymentCurrency === 'IDR' ? '0.01' : currencyStep;
    if (paymentCurrency !== region.currency.code) {
      const conversion = await api.convert_currency({
        from: region.currency.code,
        to: paymentCurrency,
        amount: cart.total,
      });
      if (conversion.outcome) {
        throw new Error(conversion.message);
      }
      paymentTotal = conversion.amount;
      currencyStep = CoreUtils.currency.precision_to_step(
        DataCurrencies[paymentCurrency as keyof typeof DataCurrencies].decimal_digits
      );
    }

    const totalCents = Big(paymentTotal).div(paymentCurrencyStep).toFixed(0);

    return {
      total: cart.total,
      currency: paymentCurrency,
      totalCents: parseInt(totalCents, 10),
    };
  }

  @computed get orderValidated() {
    return doOrderValidation({
      restaurant: this.store.restaurant,
      checkout: this.s,
      store: this.store,
    });
  }

  @computed get termValidated() {
    return validateTerms({
      restaurant: this.store.restaurant,
      checkout: this.s,
      store: this.store,
    });
  }

  @computed get customerInformationValidated() {
    return validateCustomerInformation({
      restaurant: this.store.restaurant,
      checkout: this.s,
      store: this.store,
    });
  }

  @computed get customCheckoutFieldsValidated() {
    return validateCustomCheckoutFields({
      restaurant: this.store.restaurant,
      checkout: this.s,
      store: this.store,
    });
  }

  @action validatePreOrderDataForStripePayment() {
    const error = this.orderValidated;
    if (error) {
      this.s.error = error;
      this.s.error_details = '';
      return error;
    }
    this.s.error = '';
    this.s.error_details = '';
    return;
  }

  @action validateOrderData() {
    const error =
      this.orderValidated ||
      this.termValidated ||
      this.customCheckoutFieldsValidated ||
      this.customerInformationValidated;
    if (error) {
      this.s.error = error;
      this.s.error_details = '';
      return error;
    }
    this.s.error = '';
    this.s.error_details = '';
    return;
  }

  @action computeStripeUberCollectedFees() {
    const { store } = this;
    const tip = store.cart.tipAmount;
    const deliveryFee = (store.cart.fees || []).length > 0 ? store.cart.fees[0].value : 0;
    return (tip || 0) + (deliveryFee || 0);
  }

  @action async computeStripeCollectedFees(deliveryProvider: string | undefined) {
    const { store } = this;
    const r = store.restaurant;
    const oc = store.order_config.s;
    const tip = store.cart.tipAmount;
    const deliveryFee = store.cart.calculateDeliveryProviderFee(deliveryProvider, r, oc).fee || 0;
    return (tip || 0) + (deliveryFee || 0);
  }

  @action getStripeConnectStatus() {
    const config = this.store.restaurant.settings.payments.stripe;
    const status = !isNil(config) && !isEmpty(config.connect_account_id) ? 'CONNECT' : 'NOT CONNECT';
    this.update({
      using_stripe_connect: status,
    });

    return status;
  }

  @action setPaymentItentId(id: string | undefined) {
    this.update({
      stripe_paymentIntent_id: id,
    });
  }
  @action setStripeCheckoutFormLoading(loading: boolean) {
    this.update({
      stripe_loading_form: loading,
    });
  }
  @action setUsingBamboraNA(loading: boolean) {
    this.update({
      using_bambora_na: loading,
    });
  }
  async createSwishPaymentRequest(orderId: string, restaurantId: string) {
    try {
      const payload: T.API.SwishCreatePaymentRequest = {
        orderId,
        restaurantId,
      };

      const url = `${config.api_url}/stores/swish/create-payment-request`;
      const { data } = await axios.post<T.API.SwishCreatePaymentResponse>(url, payload, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (data.outcome === 1) {
        this.order_error(undefined, 'payment_fail');
        return;
      }
    } catch (e) {
      this.order_error(undefined, 'payment_fail');
      return;
    }
  }

  async moveCabbagePayTransaction(publicToken: string, orderId: string, restaurantId: string) {
    try {
      const payload: T.API.CabbagePayTransactionMoveRequest = {
        publicToken,
        orderId,
        restaurantId,
      };

      const url = `${config.api_url}/stores/cabbagepay/move-transaction`;
      const { data } = await axios.post(url, payload, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (data.outcome === 1) {
        this.order_error(undefined, 'payment_fail');
        return;
      }

      return data;
    } catch (e) {
      this.order_error(undefined, 'payment_fail');
      return;
    }
  }

  async createPaymongoCheckoutSession(orderId: string, restaurantId: string) {
    const payload: T.API.SwishCreatePaymentRequest = {
      orderId,
      restaurantId,
    };

    const data = await this.store.api.createPayMongoCheckoutSession(payload);
    if (data.outcome === 1) {
      return null;
    }

    return data.checkoutUrl;
  }

  @action async order_commence(e?: React.FormEvent<HTMLFormElement>) {
    e && e.preventDefault();
    if (this.s.loading) return;
    logger.info('CHECKOUT');
    const { store } = this;
    const checkout = store.checkout.s;
    let order;
    try {
     const orderCheck = await this.validateOrderMaximumPerWindow();
      const error =
        this.orderValidated ||
        this.termValidated ||
        this.customCheckoutFieldsValidated ||
        this.customerInformationValidated ||
        orderCheck;

      if (error) {
        this.logStep(`ERROR CLIENT VALIDATION [${error}]`);
        this.s.error = error;
        this.s.error_details = '';
        return;
      }


      if(checkout.payment === 'paymongo') {
        if(isEmpty(checkout.email)){
          this.s.error = 'required_email';
          this.s.error_details = '';
          return;
        } else if(store.restaurant.settings.payments.paymongo?.min_order
          && store.restaurant.settings.payments.paymongo?.min_order > store.cart.total) {
            this.s.error = 'invalid_order_amount';
            this.s.error_details = '';
            return;
        }
      }

      this.s.error = '';
      this.s.error_details = '';
      this.s.loading = true;

      if (
        checkout.payment === 'gravity' &&
        (window.gravityExternalToken === undefined || window.gravityUniqueToken === undefined)
      ) {
        this.logStep(`NO GRAVITY TOKEN`);
        this.order_error(undefined, 'payment_fail');
        return;
      }

      if (checkout.payment === 'checkout' && (!window.checkout_token || window.checkout_token.length === 0)) {
        try {
          const response = await window.Frames.submitCard();
          window.checkout_token = response.token;
          this.logStep(`SUCCESS PAYMENT REQUEST`);
        } catch (e) {
          this.logStep(`FAILED PAYMENT REQUEST - ${JSON.stringify(e)}`);
        }
      }

      if (this.s.payment === 'ath_movil') {
        const checkout = this.s;
        if (checkout.ath_movil_status === true) {
          this.logStep(`ATH_MOVIL_STATUS = ${checkout.ath_movil_status}`);
        } else {
          const athMovilButton = document.getElementById('ATHMovil_Checkout_Button');
          if (athMovilButton) {
            // The user may have clicked "Place Order" before the ATH Movil button is ready.
            // So, we need to wait until it is ready before attempting to click it.
            while (!athMovilButton.classList.contains('ATHMovilbtn')) {
              await new Promise(r => setTimeout(r, 200));
              this.logStep(`WAITING FOR BUTTON`);
            }

            if (athMovilButton.classList.contains('ATHMovilbtn')) {
              athMovilButton.click();
              this.s.loading = false;
              this.logStep(`ATH MOVIL BUTTON GHOST CLICKED`);
              return;
            }
          }
        }
      }

      // If the payment fails, and this code is run again, we want to try and get another ATH Movil payment
      checkout.ath_movil_status = false;

      this.logStep(`BUILDING ORDER OBJECT`);
      order = await this.order_build();
      if (!order) {
        this.logStep(`FAILED TO BUILD ORDER OBJECT`);
        return;
      }
      this.logStep(`SUCCESS BUILDING ORDER OBJECT`);

      this.logStep(`VALIDATING ORDER`);
      const validate = await this.store.api.order_validate({ order });

      if (checkout.payment === 'gravity') {
        order.payment.gravity_external_token = window.gravityExternalToken;
        order.payment.gravity_unique_token = window.gravityUniqueToken;
        this.logStep(`GRAVITY TOKEN SET`);
      }

      if (checkout.payment === 'authorized') {
        if (window.authorized_data && window.authorized_data.opaqueData !== null) {
          order.payment.authorized_data = {
            opaqueData: window.authorized_data.opaqueData,
            zipCode: window.authorized_data.zipCode,
          };
          this.logStep(`ORDER OBJECT UPDATED WITH RETURN DATA`);
        } else {
          this.order_error(undefined, 'payment_fail');
          this.logStep(`PAYMENT FAILED`);
          return;
        }
      }

      if (checkout.payment === 'checkout') {
        order.payment.checkout_token = window.checkout_token;
        if (
          this.store.restaurant.settings.payments.checkout &&
          this.store.restaurant.settings.payments.checkout.checkout_public_key
        ) {
          window.Frames.init(this.store.restaurant.settings.payments.checkout.checkout_public_key);
          this.logStep(`INITIALIZED WITH PUBLIC KEY`);
        } else {
          window.Frames.init('');
          this.logStep(`INITIALIZED WITH EMPTY STRING`);
        }
        window.Frames.isCardValid();
      }

      if (checkout.payment === 'ath_movil') {
        order.payment.reference = window.ath_reference;
        this.logStep(`ORDER OBJECT ASSIGNED WITH ATH REF FROM WINDOW`);
      }

      if (validate.outcome) {
        this.order_error(undefined, validate.message);
        this.logStep(`VALIDATION FAILED WITH ${validate.message}`);
        return;
      }

      if (order.payment.method === 'paypal') {
        this.paypal_init(order);
        this.logStep(`INITIALIZED`);
      } else if (order.payment.method === 'poli_pay') {
        await this.poli_init(order);
        this.logStep(`INITIALIZED`);
      } else if (order.payment.method === 'paygate_payweb') {
        await this.paygate_init(order);
        this.logStep(`INITIALIZED`);
      } else if (order.payment.method === 'ipay88') {
        await this.ipay88_init(order);
        this.logStep(`INITIALIZED`);
      } else if (order.payment.method === 'pesapal') {
        await this.pesapal_init(order);
        this.logStep(`INITIALIZED`);
      } else {
        const requestArgs: T.API.StoresOrderCreateRequest = { order };

        if (order.payment.method === 'stripe') {
          if (!(await this.confirmStripePayment(order, requestArgs))) {
            return;
          }
        }

        if (order.payment.method === 'cardconnect') {
          requestArgs.ccToken = checkout.card_token;
          requestArgs.ccExpiry = checkout.card_expiry;
          requestArgs.ccCVV = checkout.card_cvv;
          requestArgs.validateToken = await genRecaptchaToken();
        }

        if (order.payment.method === 'bambora_apac') {
          requestArgs.ccToken = checkout.card_token;
        }

        if (order.payment.method === 'bambora_na') {
          order.payment.status = 'pending';
        }

        if (order.payment.method === 'elavon') {
          try {
            const elavonTokenizationPayload = {
              amount: store.cart.total,
              restaurant_id: store.restaurant._id,
              card_number: checkout.card_number,
              card_expiry: checkout.card_expiry,
              card_cvv: checkout.card_cvv,
              billing_address: checkout.address_field,
              zip: checkout.zip,
            };

            this.logStep(`PREPARE TOKEN REQUEST`);
            const res = await axios.post(`${config.api_url}/stores/elavon-token`, elavonTokenizationPayload, {
              headers: { 'Content-Type': 'application/json' },
            });

            const data = res.data;
            if (data.outcome === 1) {
              this.order_error(undefined, 'payment_fail');
              this.logStep(`TOKEN REQUEST FAILED = ${data.outcome} - ${data.message}`);
              return;
            }

            requestArgs.elavonToken = data.token;
            requestArgs.validateToken = await genRecaptchaToken();
          } catch (e) {
            this.order_error(undefined, 'payment_fail');
            this.logStep(`TOKEN REQUEST UNHANDLED ERROR = ${JSON.stringify(e)}`);
            return;
          }
        }

        if (order.payment.method === 'red_dot') {
          try {
            const redDotTokenizationPayload = {
              order_id: order._id,
              restaurant_id: store.restaurant._id,
              card_number: checkout.card_number,
              card_expiry: checkout.card_expiry,
              card_cvv: checkout.card_cvv,
              customer_name: order.customer.name,
              customer_email: order.customer.email,
            };

            const res = await axios.post(`${config.api_url}/stores/red-dot-token`, redDotTokenizationPayload, {
              headers: { 'Content-Type': 'application/json' },
            });

            const data = res.data;
            if (data.outcome == 1) {
              this.order_error(undefined, 'payment_fail');
              return;
            }

            requestArgs.redDotToken = data.token;
          } catch (e) {
            this.order_error(undefined, 'payment_fail');
            return;
          }
        }

        if (order.payment.method === 'stripe_digital_wallet') {
          requestArgs.stripeConnectPaid = checkout.using_stripe_connect === 'CONNECT';
          requestArgs.stripeConnectPaymentIntentId = requestArgs.stripeConnectPaid
            ? this.s.stripe_digital_wallet_payment_intent_id
            : undefined;
          order.payment.stripe_digital_wallet_payment_intent_id = this.s.stripe_digital_wallet_payment_intent_id;
          order.payment.stripe_digital_wallet_payment_client_secret =
            this.s.stripe_digital_wallet_payment_client_secret;
        }

        if (order.payment.method === 'bancontact') {
          requestArgs.stripeConnectPaid = true;
          requestArgs.stripeConnectPaymentIntentClientSecret = this.s.stripe_bancontact_payment_intent_client_secret;
          requestArgs.stripeConnectPaymentIntentId = this.s.stripe_bancontact_payment_intent_id;
          order.payment.status = 'success';
          order.payment.stripe_connect_payment_intent_client_secret =
            requestArgs.stripeConnectPaymentIntentClientSecret;
          order.payment.stripe_connect_payment_intent_id = requestArgs.stripeConnectPaymentIntentId;
        }

        if (order.payment.method === 'swish') {
          order.payment.status = 'pending';
        }

        if (order.payment.method === 'fiserv') {
          order.payment.status = 'pending';
        }

        if (order.payment.method === 'viva_wallet') {
          order.payment.status = 'pending';
        }

        if (order.payment.method === 'cabbagepay') {
          order.payment.status = 'pending';
        }

        if (order.payment.method === 'authorized' || order.payment.method === 'gravity') {
          requestArgs.validateToken = await genRecaptchaToken();
        }
        return await this.order_complete(requestArgs);
      }
    } catch (e) {
      this.order_error(e, 'Something went wrong while confirming your order. Please check with our staff.');
      if (order && order.payment.method === 'stripe') {
        await store.checkout.handleFailedOrderCreation(
          order.payment.reference ?? '',
          store.restaurant._id,
          order._id,
          `Unhandled exception: ${e}`
        );
      }
    }
  }

  // create order_commence_v2 for stripe payment only
  @action async order_commence_v2(e?: React.FormEvent<HTMLFormElement>) {
    e && e.preventDefault();
    if (this.s.loading) return;
    logger.info('[STRIPE V2] CHECKOUT');
    const {store} = this;
    const checkout = store.checkout.s;
    try {
      // validate field(s) before create draft
      const orderCheck = await this.validateOrderMaximumPerWindow();
      const error = this.orderValidated || this.termValidated || this.customCheckoutFieldsValidated || this.customerInformationValidated || orderCheck;
      if (error) {
        this.logStep(`[STRIPE V2] ERROR CLIENT VALIDATION [${error}]`);
        this.s.error = error;
        this.s.error_details = '';
        return;
      }
      this.s.error = '';
      this.s.error_details = '';
      this.s.loading = true;

      // prepare data to create a draft order
      // 0. validate if order exist
      const existingOrder = await store.api.order_check({ _id: localStorage.getItem("initial_order_id") || '', isPostHookRequire: false });
      if (!existingOrder.outcome && existingOrder.order.status !== 'draft') {
        this.order_error(e, 'Your order has been created successfully. Please wait');
        // @ts-ignore
        return await this.order_complete_v2(existingOrder);
      }

      // 1. validate checkout.payment
      await this.validateCheckoutPayment(checkout.payment);

      // 2. validate ath_movil if this.s.payment === ath.movil
      await this.validateAthMovilPayment(this.s.payment);

      checkout.ath_movil_status = false;

      // 3. create draft order
      this.logStep(`[STRIPE V2] BUILDING DRAFT ORDER OBJECT`);
      const customerInfo = this.store.customer.s.item;
      const draftOrderArg: T.API.StoresOrderBuildRequest = {
        store: {
          restaurant: this.store.restaurant,
          customer: {
            item: {
              ...customerInfo,
              // @ts-ignore
              meta: {
                last_ip: geotargetly_ip(),
                ip_country: geotargetly_country_code(),
                ip_region: geotargetly_region_code(),
                ip_city: geotargetly_city_name(),
                ip_lat: geotargetly_lat(),
                ip_lng: geotargetly_lng()
              }

            }
          },
          order_config: this.store.order_config.s,
          cart: this.store.cart.s,
          checkout: {...this.store.checkout.s},
          intl: {...this.store.intl.s, i18nName: this.store.intl.i18n.t('order.totals.delivery_fee')}
        },
        checkout: this.store.checkout.s,
        initial_order_id: this.store.order.getGeneratedOrderId() // 3rd change
      };
      const draftOrder = await this.store.api.order_draft_create(draftOrderArg)
      this.logStep(`[STRIPE V2] BUILDING DRAFT ORDER OBJECT SUCCESS`);
      if (!draftOrder.outcome) {
        this.logStep(`[STRIPE V2] PAYMENT PROCESS START`);
        const orderDetail = {
          name: draftOrder.order.customer.name,
          email: draftOrder.order.customer.email,
          phone: draftOrder.order.customer.phone,
          payment: {
            status: draftOrder.order.payment.status,
            reference: draftOrder.order.payment.reference,
            method: draftOrder.order.payment.method
          },
        }
        localStorage.setItem("save_customer_ordered", JSON.stringify(draftOrder.customer))
        switch (draftOrder.order.payment.method) {
          case 'stripe':
            await this.confirmStripePayment_v2(draftOrder);
            break;
          case 'bambora_na':
            this.setUsingBamboraNA(true);
            return await createBamboraNAPaymentRequest_v2(this.store, draftOrder);
        }
        // if (draftOrder.order.payment.method === "stripe") {
        //   if (!(await this.confirmStripePayment_v2(orderDetail))) {
        //         return;
        //       }
        // }
        // if (draftOrder.order.payment.method === "bambora_na") {
        //   this.setUsingBamboraNA(true);
        //   return await createBamboraNAPaymentRequest(this.store, draftOrder.order._id);
        // }
        // 4. start order_complete:
        delete draftOrder.outcome
        this.logStep(`[STRIPE V2] PAYMENT PROCESS FINISHED`);

        // 5. subscribe ably channel:
        // create connect to ably
        if (!this.ably) {
          this.ably = new Ably.Realtime(config.ably_order_updates_key);
          this.ably.connection.on(this.ablyHandleConnectionState);
        }

        if (this.channel) {
          this.channel.unsubscribe();
        }
        this.logStep(`[STRIPE V2] ABLY NOTIFY ORDER STATUS`);


        this.channel = this.ably.channels.get(`public:order-updates:${draftOrder.order._id}`);

        this.channel.subscribe( (message: Ably.Types.Message) => {
          const data = message.data as {
            status: string;
            updated: number;
          };
          if (message.name === 'status' && data.status === 'new_order') {
            return this.order_complete_v2(draftOrder);
          }
          // else {
          //   console.log("There's some error!!!");
          //   return
          // }
        });
        // setTimeout(() => {
        //       const activeModal = localStorage.getItem("saveActiveReceiptOrder");
        //        if(!activeModal){
        //        this.checkOrderPaymentStatus(draftOrder)
        //      } else {
        //       localStorage.removeItem("saveActiveReceiptOrder")
        //      }
        //     }
        //   , 5000);
        return draftOrder.order;
      } else {
        this.logStep(`ORDER CREATION API RESPONSE MESSAGE = ${draftOrder.message}`);
        this.order_error(undefined, draftOrder.message);
        this.logStep(`STRIPE CAN'T CONFIRM PAYMENT = ${draftOrder.message}`);
        return;
      }
    } catch (e) {
      this.logStep(`[STRIPE V2] ERROR WHILE CONFIRMING ORDER`);
      console.log(e);
      this.order_error(e, 'Something went wrong, please refresh the page and try again');
    }
  }

  @action async validateCheckoutPayment(checkoutPayment: string) {
    if (
      checkoutPayment === 'gravity' &&
      (window.gravityExternalToken === undefined || window.gravityUniqueToken === undefined)
    ) {
      this.logStep(`NO GRAVITY TOKEN`);
      this.order_error(undefined, 'payment_fail');
      return;
    }

    if (checkoutPayment === 'checkout' && (!window.checkout_token || window.checkout_token.length === 0)) {
      try {
        const response = await window.Frames.submitCard();
        window.checkout_token = response.token;
        this.logStep(`SUCCESS PAYMENT REQUEST`);
      } catch (e) {
        this.logStep(`FAILED PAYMENT REQUEST - ${JSON.stringify(e)}`);
      }
    }
  }

  @action async validateAthMovilPayment(storePayment: string) {
    if (storePayment === 'ath_movil') {
      const checkout = this.s;
      if (checkout.ath_movil_status) {
        this.logStep(`ATH_MOVIL_STATUS = ${checkout.ath_movil_status}`);
      } else {
        const athMovilButton = document.getElementById('ATHMovil_Checkout_Button');
        if (athMovilButton) {
          // The user may have clicked "Place Order" before the ATH Movil button is ready.
          // So, we need to wait until it is ready before attempting to click it.
          while (!athMovilButton.classList.contains('ATHMovilbtn')) {
            await new Promise(r => setTimeout(r, 200));
            this.logStep(`WAITING FOR BUTTON`);
          }

          if (athMovilButton.classList.contains('ATHMovilbtn')) {
            athMovilButton.click();
            this.s.loading = false;
            this.logStep(`ATH MOVIL BUTTON GHOST CLICKED`);
            return;
          }
        }
      }
    }
  }

  @action async order_complete(data: T.API.StoresOrderCreateRequest) {
    const store = this.store;
    const c = store.customer.s.item;
    this.logStep(`ORDER_COMPLETE START`);
    this.logStep(`ORDER CREATION API`);
    const paymentIntentId = data.order.payment.reference ?? '';
    const orderId = data.order._id ?? '';
    const response = await store.api.order_create(data);

    this.logStep(`ORDER CREATION API RESULT = ${response.outcome}`);
    // HANDLE FAIL OUTCOME
    if (response.outcome) {
      this.logStep(`ORDER CREATION API RESPONSE MESSAGE = ${response.message}`);
      this.order_error(undefined, response.message);
      if (data.order.payment.method === 'stripe') {
        await store.checkout.handleFailedOrderCreation(
          paymentIntentId,
          store.restaurant._id,
          orderId,
          `order_create result: ${response.message}`
        );
      }

      return;
    }

    // HANDLE SUCCESS
    const { order, customer } = response;
    this.logStep(`ORDER CREATED`);

    // UPDATE CUSTOMER & ORDER PROFILE
    if (customer && customer.type !== 'guest') {
      customer.loyalty = c?.loyalty;
      store.customer.update({ item: customer });

      // UPDATE ORDER HISTORY
      const order_history = [...store.order_history.s.items];
      order_history.splice(order_history.length - 1, 1);
      order_history.unshift(order);
      store.order_history.update({ items: order_history });
      this.logStep(`CUSTOMER ORDER HISTORY UPDATED`);
    }

    store.order.update({ item: order });

    this.logStep(`GENERIC PAYMENT HANDLING`);

    // This is used for Checkout.js payment
    if (order.payment.authentication_redirect_url) {
      store.order.update({ showCardPaymentAuthenticationModal: true });
    }

    if (order.payment.method === 'gkash') {
      store.order.update({ paymentFormModal: 'gkash' });
    }

    if (order.payment.method === 'sgepay') {
      store.order.update({ paymentFormModal: 'sgepay' });
    }

    if (order.payment.method === 'payway' && order.payment.payway_transaction_params) {
      store.order.update({ paymentFormModal: 'payway' });
    }

    if (order.payment.method === 'swish') {
      // We create Swish payment request here to avoid passing order total
      // amount via API call. This is bit more secure compared to passing
      // total amount from client side.
      await this.createSwishPaymentRequest(order._id, store.restaurant._id);
      store.order.update({ paymentFormModal: 'swish' });
    }

    if (order.payment.method === 'fiserv') {
      store.order.update({ paymentFormModal: 'fiserv' });
    }

    if (order.payment.method === 'viva_wallet') {
      store.order.update({ paymentFormModal: 'viva_wallet' });
    }

    if (order.payment.method === 'cabbagepay') {
      store.order.update({ paymentFormModal: 'cabbagepay' });
    }

    if (order.payment.method === 'paymongo') {
      await this.createPaymongoCheckoutSession(order._id, store.restaurant._id);
      store.order.update({ paymentFormModal: 'paymongo' });
    }

    if (order.payment.method === 'bambora_na') {
      await createBamboraNAPaymentRequest(this.store, order._id);
    }

    this.logStep(`SESSION/CART/DINE-IN RESET`);
    // CLEAR CART AND ORDER SETUP
    const table_id = store.order_config.s.table_id;
    const table_pw = store.order_config.s.table_password;
    // const table_id = window.table_id;
    // const table_pw = window.table_password;
    store.sessionRemoveCart();
    store.cart.clear();
    store.order_config.setService('');

    this.logStep(`PAYMENT INTENT RESET`);
    this.resetPayments();

    this.logStep(`ORDER RESET`);
    this.store.sessionRemoveOrder();

    this.logStep(`UBER RESET`);
    this.store.order_config.update({ uber_quotation_id: '' });

    // UGLY FORCE STARTING OF ORDERING BY TABLE (IF TABLE IS PROVIDED)
    const service = store.restaurant.settings.services.dine_in;

    const table = service.tables.find(t => t._id === table_id);

    if (table && (!table.password || table.password === table_pw)) {
      const now = store.intl.momentNow();
      const { first_order_offset, last_order_offset } = service.times.conditions;
      const hours = service.hours?.length > 0 ? service.hours : store.restaurant.location.opening_hours;
      const isOpen = RestaurantUtils.opening_hours.isTimeWithin({
        dt: now.clone(),
        timezone: store.intl.s.tz,
        hours: hours,
        special_hours: store.restaurant.location.special_hours,
        first_offset: first_order_offset || 0,
        last_offset: last_order_offset || 0,
      }).isWithin;

      if (isOpen) {
        store.sessionRemoveCart();
        store.cart.clear();
        store.order_config.setService('dine_in');
        store.order_config.setDue('now');
        store.order_config.setTable(String(table_id), table.name);
        store.order_config.setTablePassword(String(table_pw) || '');
        store.order_config.setConfirmed();
      } else {
        store.order_config.setTable('closed', '');
      }
    }

    // SAVE PROMO TO LOCAL STORAGE IF NOT LOGGED IN
    if (order.promos.length > 0 && order.promos[0] && !c) {
      localStorage.setItem(order.promos[0]._id, 'true');
    }

    if (window.stripeCard) {
      window.stripeCard.clear();
      this.logStep(`CLEARED STRIPE CARD OBJECT`);
    }

    // CLOSE MODAL
    store.modal.s.active = '';
    store.modal.s.last = '';

    // STOP LOADING, REMOVE ERROR & CLEAR CHECKOUT BUT PRESERVE CUSTOMER DETAILS
    const newState = omit(this.initialState(), ['name', 'email', 'phone']);
    this.update(newState);

    Untrusive.stop();

    // Jump to Order Route to show the order modal
    if (!['checkout_apple_pay', 'razor_pay'].includes(order.payment.method)) {
      store.router.push(`/order/${order._id}`);
      this.logStep(`REDIRECT TO ORDER MODAL`);
    }

    fbq('track', 'Purchase', {
      value: order.bill.total,
      num_items: order.dishes.reduce((a, v) => a + v.qty, 0),
      currency: order.bill.currency,
    });
    this.logStep(`FB PIXEL UPDATE`);
    localStorage.removeItem('isSelectedFreeItem');

    // reset initial_order_id
    this.store.order.generateOrderId(true);
    // get order history
    this.store.order_history.get(1);
    return order;
  }

  @action async order_complete_v2(draftOrder: {order: T.Schema.Order.OrderSchema, customer: T.Schema.Customer.CustomerSchema}) {
    const { order, customer } = draftOrder;
    const store = this.store;
    const c = store.customer.s.item;
    // UPDATE CUSTOMER & ORDER PROFILE
    if (customer && customer.type !== 'guest') {
      customer.loyalty = c?.loyalty;
      store.customer.update({ item: customer });

      // UPDATE ORDER HISTORY
      const order_history = [...store.order_history.s.items];
      order_history.splice(order_history.length - 1, 1);
      order_history.unshift(order);
      store.order_history.update({ items: order_history });
      this.logStep(`CUSTOMER ORDER HISTORY UPDATED`);
    }

    store.order.update({ item: order });

    this.logStep(`GENERIC PAYMENT HANDLING`);

    // This is used for Checkout.js payment
    if (order.payment.authentication_redirect_url) {
      store.order.update({ showCardPaymentAuthenticationModal: true });
    }

    this.logStep(`SESSION/CART/DINE-IN RESET`);
    // CLEAR CART AND ORDER SETUP
    const table_id = store.order_config.s.table_id;
    const table_pw = store.order_config.s.table_password;
    store.sessionRemoveCart();
    store.cart.clear();
    store.order_config.setService('');

    this.logStep(`PAYMENT INTENT RESET`);
    this.resetPayments();

    this.logStep(`ORDER RESET`);
    this.store.sessionRemoveOrder();

    this.logStep(`UBER RESET`);
    this.store.order_config.update({ uber_quotation_id: '' });


    // UGLY FORCE STARTING OF ORDERING BY TABLE (IF TABLE IS PROVIDED)
    const service = store.restaurant.settings.services.dine_in;

    const table = service.tables.find(t => t._id === table_id);

    if (table && (!table.password || table.password === table_pw)) {
      const now = store.intl.momentNow();
      const { first_order_offset, last_order_offset } = service.times.conditions;
      const hours = service.hours?.length > 0 ? service.hours : store.restaurant.location.opening_hours;
      const isOpen = RestaurantUtils.opening_hours.isTimeWithin({
        dt: now.clone(),
        timezone: store.intl.s.tz,
        hours: hours,
        special_hours: store.restaurant.location.special_hours,
        first_offset: first_order_offset || 0,
        last_offset: last_order_offset || 0,
      }).isWithin;

      if (isOpen) {
        store.sessionRemoveCart();
        store.cart.clear();
        store.order_config.setService('dine_in');
        store.order_config.setDue('now');
        store.order_config.setTable(String(table_id), table.name);
        store.order_config.setTablePassword(String(table_pw) || '');
        store.order_config.setConfirmed();
      } else {
        store.order_config.setTable('closed', '');
      }
    }

    // SAVE PROMO TO LOCAL STORAGE IF NOT LOGGED IN
    if (order.promos.length > 0 && order.promos[0] && !c) {
      localStorage.setItem(order.promos[0]._id, 'true');
    }

    if (window.stripeCard) {
      window.stripeCard.clear();
      this.logStep(`CLEARED STRIPE CARD OBJECT`);
    }

    // CLOSE MODAL
    store.modal.s.active = '';
    store.modal.s.last = '';

    // STOP LOADING, REMOVE ERROR & CLEAR CHECKOUT BUT PRESERVE CUSTOMER DETAILS
    const newState = omit(this.initialState(), ['name', 'email', 'phone']);
    this.update(newState);

    Untrusive.stop();
    localStorage.removeItem("save_customer_ordered")
    localStorage.removeItem("saveActiveReceiptOrder")

    // Jump to Order Route to show the order modal
    if (!['checkout_apple_pay', 'razor_pay'].includes(order.payment.method)) {
      store.router.push(`/order/${order._id}`);
      this.logStep(`REDIRECT TO ORDER MODAL`);
    }

    fbq('track', 'Purchase', {
      value: order.bill.total,
      num_items: order.dishes.reduce((a, v) => a + v.qty, 0),
      currency: order.bill.currency,
    });
    this.logStep(`FB PIXEL UPDATE`);
    localStorage.removeItem('isSelectedFreeItem');

    // reset initial_order_id
    this.store.order.generateOrderId(true);
    return order;
  }
  @action async checkOrderPaymentStatus(draftOrder: {order: T.Schema.Order.OrderSchema, customer: T.Schema.Customer.CustomerSchema}){
    const { store } = this;
    let orderChecking = await store.api.order_check({ _id: draftOrder.order._id, isPostHookRequire: true, isStandardStripeCheck: true });
    if (!orderChecking.outcome) {
      const { order }: any = orderChecking;
      if (order.status === "draft"){
        store.modal.back();
        this.s.loading = false;
        this.resetPayments();
        this.store.order_config.update({ uber_quotation_id: '' });
      } else if (order._id === localStorage.getItem("initial_order_id")) {
        await this.order_complete_v2(draftOrder);
      }
    }
  }
  @action async order_callback() {
    this.logStep(`ORDER CALL_BACK START`);
    const { store } = this;
    try {
      const router = store.router.s;
      const order_id = router.query.callback_order;
      const type = router.query.type;

      store.router.push('/');

      const restored =
        type === 'pesapal'
          ? store.sessionRestore('pesapal')
          : type === 'bambora_apac'
          ? store.sessionRestore('bambora_apac')
          : type === 'ipay88'
          ? store.sessionRestore('ipay88')
          : store.sessionRestore(order_id);

      store.loader.update({
        active: false,
        opacity: 0,
        title: '',
        message: '',
      });

      setTimeout(() => {
        try {
          const el = document.getElementById('checkout-button');
          if (el) {
            el.scrollIntoView();
          }
        } catch (e) {
          logger.captureException(e);
        }
      }, 1000);

      if (!restored) return;

      if (type === 'poli_pay') {
        const outcome = router.query.outcome as 'success' | 'fail' | 'cancelled';

        if (outcome === 'cancelled' || outcome === 'fail') {
          this.order_error(undefined, `payment_${outcome}`);
          return;
        }

        const token = router.query.token;
        const order = await this.order_build();
        order._id = order_id;
        order.payment.status = 'success';
        order.payment.reference = token;

        await this.order_complete({ order });
      } else if (type === 'paygate_payweb') {
        const { TRANSACTION_STATUS, PAY_REQUEST_ID, CHECKSUM } = router.query as {
          callback_order: string;
          type: 'paygate_payweb';
          PAY_REQUEST_ID: string;
          TRANSACTION_STATUS: string;
          CHECKSUM: string;
        };

        const meta = restored as T.Lib.Payments.PayGatePayWebInitResponse;

        if (TRANSACTION_STATUS === '2' || TRANSACTION_STATUS === '7') {
          // DECLINED / SETTLEMENT VOIDED
          this.order_error(undefined, `payment_fail`);
          return;
        } else if (TRANSACTION_STATUS === '3' || TRANSACTION_STATUS === '4') {
          // CANCELED / USER CANCELLED
          this.order_error(undefined, `payment_cancelled`);
          return;
        }

        const order = await this.order_build();
        order._id = order_id;

        // APPROVED OR RECEIVED BY PAYGATE ELSE PENDING
        order.payment.status = TRANSACTION_STATUS === '1' || TRANSACTION_STATUS === '5' ? 'success' : 'pending';
        order.payment.reference = PAY_REQUEST_ID;
        order.payment.reference_status = TRANSACTION_STATUS;
        order.payment.paygate_payweb = meta;

        await this.order_complete({ order });
      } else if (type === 'ipay88') {
        const data = router.query as T.Lib.Payments.Ipay88InitResponse;

        if (data.Status === '0') {
          this.order_error(undefined, `payment_fail`);
          return;
        }

        window.location.pathname = `/order/${order_id}`;
      } else if (type === 'bambora_apac') {
        const outcome = router.query.outcome as undefined | 'cancelled';

        if (outcome === 'cancelled') {
          this.order_error(undefined, `payment_cancelled`);
          return;
        }

        const order = await this.order_build();

        order._id = order_id;

        await this.order_complete({ order });
      } else if (type === 'pesapal') {
        const { pesapal_transaction_tracking_id } = router.query;

        const order = await this.order_build();

        order._id = order_id;
        order.payment.status = 'pending';
        order.payment.reference = pesapal_transaction_tracking_id;

        await this.order_complete({ order });
      }
    } catch (e) {
      this.order_error(e);
      this.logStep(`ORDER CALL_BACK UNHANDLED EXCEPTION ${JSON.stringify(e)}`);
    }
    this.logStep(`ORDER CALL_BACK END`);
  }

  @action async order_build() {
    this.logStep(`ORDER BUILD START`);
    const r = this.store.restaurant;
    const auth = this.store.customer.s;
    const customer = auth.item;
    const oc = this.store.order_config.s;
    const checkout = this.s;
    const cart = this.store.cart;
    const { region } = r.settings;

    let paymentStripeConnect = undefined;
    if (checkout.payment === 'stripe') {
      paymentStripeConnect =
        checkout.using_stripe_connect === 'CONNECT'
          ? true
          : checkout.using_stripe_connect === 'NOT CONNECT'
          ? false
          : undefined;
    }

    const { total, totalCents, currency } = await this.computePaymentDetails();

    const dishes = cloneDeepSafe(cart.s.items);
    for (let i = 0; i < dishes.length; i++) {
      RestaurantUtils.trim.dish(dishes[i]);
      dishes[i].require_age_verification = r.menus.find(menu => menu._id === dishes[i].menu_id)?.conditions.age_restricted;
    }

    // remove checkout fields with empty answer
    const filteredCheckoutFields = cloneDeepSafe(checkout.checkout_fields).filter(field => field.answer);

    const prep_order_id = this.store.order.getGeneratedOrderId() || nanoid();
    //console.log(`INIT_ORDER_ID: ${prep_order_id}`);
    const order: T.Schema.Order.OrderCreateSchema = {
      _id: prep_order_id,
      notes: checkout.notes,
      checkout_fields: filteredCheckoutFields,
      bill: {
        currency: region.currency.code,
        total: cart.total,
        total_cents: totalCents,
        cart: cart.totalCart,
        discount: cart.discount,
        taxes: cart.taxes,
        tax_in_prices: r.settings.region.tax.in_prices,
        fees: cart.fees,
        tip: cart.s.tip === '' ? 0 : parseFloat(cart.s.tip),
      },
      payment: {
        method: checkout.payment,
        subMethod: checkout.subPayment !== undefined ? checkout.subPayment : undefined,
        method_alias: checkout.payment !== checkout.selectedPayment ? checkout.selectedPayment : undefined,
        currency,
        total,
        total_cents: totalCents,
        status: 'pending',
        stripe_connect: paymentStripeConnect,
      },
      customer: {
        _id: customer ? customer._id : '',
        name: checkout.name,
        phone: checkout.phone,
        email: checkout.email,
        zip: checkout.zip,
      },
      promos: toJS(cart.s.promos),
      dishes: dishes,
      config: toJS(oc),
    };

    if (oc.service === 'delivery') {
      if (oc.delivery_provider === 'uber' && oc.uber_total_fee) {
        order.bill.fees[0].delivery_fee_restaurant = oc.uber_total_fee - order.bill.fees[0].value
      }
      order.config.delivery_provider = RestaurantUtils.settings.getDefaultDeliveryProvider(r);
    }

    try {
      order.customer.ip = geotargetly_ip();
      order.customer.country = geotargetly_country_code();
      order.customer.region = geotargetly_region_code();
      order.customer.city = geotargetly_city_name();
      order.customer.lat = geotargetly_lat();
      order.customer.lng = geotargetly_lng();
    } catch (e) {
      logger.captureException(e);
    }

    this.logStep(`ORDER BUILD END`);
    return order;
  }

  @action order_error = (e?: any, message?: string, error_details?: string) => {
    if (e && e !== 'Network Error') {
      logger.captureException(e);
    }
    this.s.paypal_show = false;
    this.s.error = message || 'something_went_wrong'; // this is a translation code
    this.s.error_details = error_details || '';
    this.s.loading = false;
    Untrusive.stop();
    this.store.loader.update({
      active: false,
      opacity: 0,
      title: '',
      message: '',
    });

    return this.logStep(`ORDER_ERROR ${e}, MESSAGE: ${message}`);
  };

  @action poli_init = async (o: T.Schema.Order.OrderCreateSchema) => {
    const { store } = this;

    const origin = store.router.getOrigin();

    const response = await store.api.payment_poli_pay_init({
      total: o.payment.total,
      origin: origin,
      order: o,
    });

    if (response.outcome) {
      this.order_error(undefined, response.message);
      return;
    }

    store.sessionSave(o._id, store.getCompleteSession());

    window.location.replace(response.data.NavigateURL);
  };

  @action paygate_init = async (o: T.Schema.Order.OrderCreateSchema) => {
    const { store } = this;

    const origin = store.router.getOrigin();

    const response = await store.api.payment_paygate_payweb_init({
      total_cents: o.payment.total_cents,
      order_id: o._id,
      origin: origin,
      customer_email: o.customer.email,
    });

    if (response.outcome) {
      this.order_error(undefined, response.message);
      return;
    }

    store.sessionSave(o._id, {
      ...store.getCompleteSession(),
      meta: response.data,
    });

    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', 'https://secure.paygate.co.za/payweb3/process.trans');
    form.style.display = 'none';

    const PAY_ID_INPUT = document.createElement('input');
    PAY_ID_INPUT.setAttribute('name', 'PAY_REQUEST_ID');
    PAY_ID_INPUT.setAttribute('value', response.data.PAY_REQUEST_ID);

    const CHECKSUM_INPUT = document.createElement('input');
    CHECKSUM_INPUT.setAttribute('name', 'CHECKSUM');
    CHECKSUM_INPUT.setAttribute('value', response.data.CHECKSUM);

    form.appendChild(PAY_ID_INPUT);
    form.appendChild(CHECKSUM_INPUT);
    document.body.appendChild(form);
    form.submit();
  };

  @action ipay88_init = async (o: T.Schema.Order.OrderCreateSchema) => {
    const { store } = this;

    const r = store.restaurant;

    const response = await store.api.payment_ipay88_init({
      order: o,
    });

    if (response.outcome) {
      this.order_error(undefined, response.message);
      return;
    }

    store.sessionSave('ipay88', {
      ...store.getCompleteSession(),
      meta: { signature: response.signature },
    });

    const origin = store.router.getOrigin();

    const method = r.settings.payments.ipay88!;

    const formData: T.Lib.Payments.Ipay88InitRequest = {
      MerchantCode: method.merchant_code,
      RefNo: o._id,
      Amount: RestaurantUtils.order.payment.ipay88.totalToAmount(o.payment.total),
      Currency: method.currency,
      ProdDesc: `Online Order - ${r.name}`,
      UserName: o.customer.name,
      UserEmail: o.customer.email,
      UserContact: o.customer.phone,
      Remark: '',
      Lang: '',
      Signature: response.signature,
      ResponseURL: `${origin}?callback_order=${o._id}&type=ipay88`,
      BackendURL: `${config.webhook_url}/stores/payment/ipay88/webhook?order_id=${o._id}&restaurant_id=${r._id}`,
    };

    if (method.payment_id) {
      formData.PaymentId = method.payment_id;
    }

    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', method.api_entry_url!);
    form.setAttribute('name', 'ePayment');
    form.style.display = 'none';
    Object.keys(formData).forEach(f => {
      const d = formData[f as keyof typeof formData];
      if (d) {
        const field_input = document.createElement('input');
        field_input.setAttribute('name', f);
        field_input.setAttribute('value', d.toString());
        form.appendChild(field_input);
      }
    });
    document.body.appendChild(form);
    form.submit();
  };

  @action paypal_init = (o: T.Schema.Order.OrderCreateSchema) => {
    const r = this.store.restaurant;

    if (!r.settings.payments.paypal) {
      return;
    }

    this.s.paypal_show = true;

    setTimeout(async () => {
      try {
        paypal.Button.render(
          {
            env: config.isTest ? 'sandbox' : 'production',
            client: {
              sandbox: r.settings.payments.paypal!.client_id,
              production: r.settings.payments.paypal!.client_id,
            },
            style: {
              size: 'responsive',
              color: 'gold',
              shape: 'rect',
            },
            commit: true,
            payment: (data: any, actions: any) => {
              let { precision } = this.store.intl.s.currency;
              precision = getCurrencyPrecision(this.store.intl.s.currency.code, precision, 2);
              const total = roundedNum(o.payment.total, precision).toString();

              return actions.payment.create(
                {
                  intent: 'sale',
                  transactions: [
                    {
                      reference_id: o._id,
                      order_url: `${window.location.origin}/order/${o._id}`,
                      description: r.name,
                      amount: {
                        total: total,
                        currency: o.payment.currency,
                      },
                    },
                  ],
                },
                {
                  input_fields: {
                    no_shipping: 1,
                  },
                }
              );
            },
            onAuthorize: (data: T.Lib.Payments.PayPalPaymentAuthorizeData, actions: any) => {
              Untrusive.start();
              return actions.payment
                .execute()
                .then((res: any) => {
                  const response = res as T.Lib.Payments.PayPalPaymentExecuteData;
                  o.payment.reference = response.id;
                  o.payment.reference_status = response.state;
                  o.payment.status = response.state === 'approved' ? 'success' : 'error';
                  o.payment.error = response.failure_reason || '';

                  delete data.returnUrl;
                  delete data.paymentToken;

                  o.payment.paypal = data;

                  (async () => {
                    try {
                      await this.order_complete({
                        order: o,
                      });
                    } catch (e) {
                      this.order_error(e);
                    }
                  })();
                })
                .catch(logger.captureException);
            },
            onCancel: this.paypal_cancel,
            onError: this.paypal_error,
          },
          '#paypalButton'
        );
      } catch (e) {
        this.order_error(e);
      }
    }, 500);
  };

  @action paypal_cancel = () => {
    this.s.paypal_show = false;
    this.s.loading = false;
    Untrusive.stop();
  };

  @action paypal_error = async (err: any) => {
    logger.info('PAYPAL ERROR');
    const message = err.message.indexOf('No value passed to payment') === -1 ? 'paypal_generic' : '';
    this.order_error(err, message);
  };

  @action bambora_apac_init = async (o: T.Schema.Order.OrderCreateSchema) => {
    const { store } = this;

    const method = store.restaurant.settings.payments.bambora_apac!;

    const origin = store.router.getOrigin();

    const response = await store.api.payment_bambora_apac_init({
      order: o,
      origin: origin,
    });

    if (response.outcome) {
      this.order_error(undefined, response.message);
      return;
    }

    store.sessionSave('bambora_apac', {
      ...store.getCompleteSession(),
      meta: { order_id: o._id, sst: response.sst },
    });

    const formData = {
      SST: response.sst,
      SessionID: o._id,
    };

    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute(
      'action',
      method.testing ? 'https://demo.bambora.co.nz/access/index.aspx' : 'https://www.bambora.co.nz/access/index.aspx'
    );
    form.style.display = 'none';
    Object.keys(formData).forEach(f => {
      const d = formData[f as keyof typeof formData];
      if (d) {
        const field_input = document.createElement('input');
        field_input.setAttribute('name', f);
        field_input.setAttribute('value', d.toString());
        form.appendChild(field_input);
      }
    });
    document.body.appendChild(form);
    form.submit();
  };

  @action pesapal_init = async (o: T.Schema.Order.OrderCreateSchema) => {
    const { store } = this;

    const origin = store.router.getOrigin();

    const response = await store.api.payment_pesapal_init({
      origin: origin,
      order: o,
    });

    if (response.outcome) {
      this.order_error(undefined, response.message);
      return;
    }

    store.sessionSave('pesapal', {
      ...store.getCompleteSession(),
      meta: { order_id: o._id },
    });

    window.location.href = response.link;
  };

  @action confirmStripePayment = async (
    order: T.Schema.Order.OrderCreateSchema,
    requestArgs: T.API.StoresOrderCreateRequest
  ) => {
    const stripeInstance = this.s.using_stripe_connect === 'CONNECT' ? window.stripeConnect! : window.stripe!;

    const { name, email, phone } = order.customer;
    const { paymentIntent, error } = await stripeInstance.confirmPayment({
      elements: window.stripeProcessingPaymentElements!,
      confirmParams: {
        return_url: `${window.location.origin}/payment/completed`,
        payment_method_data: {
          billing_details: { name, email, phone },
        },
      },
      redirect: 'if_required',
    });

    this.logStep(`RESULT_E2 ${JSON.stringify({ paymentIntentId: paymentIntent?.id, RESULT_ERROR: error })}`);

    if (error && (error.type === 'card_error' 
      || error.type === 'validation_error' 
      || error.type === 'invalid_request_error')) {
      this.order_error(undefined, `${error.message} (Code: E3)`);
      this.logStep(`RESULT_E3 ${JSON.stringify(error)}`);
      return false;
    }

    if (error && (error.type === 'api_connection_error' 
      || error.type === 'api_error' 
      || error.type === 'rate_limit_error'
      || error.type === 'idempotency_error')) {
      this.order_error(undefined, 'Unable to process payments at the moment. (Code: E4)');
      this.logStep(`RESULT_E4 ${JSON.stringify(error)}`);
      return false;
    }

    if (error) {
      this.order_error(undefined, "Unable to process payments at this time. (Code: E5)");
      this.logStep(`RESULT_E5 ${JSON.stringify(error)}`);
      return false;
    }

    if (paymentIntent) {
      this.logStep(`RESULT_E6`);
      order.payment.status = 'success';
      order.payment.reference = paymentIntent.id;
      if (this.s.using_stripe_connect) {
        requestArgs.stripeConnectPaid = true;
        requestArgs.stripeConnectPaymentIntentClientSecret = paymentIntent.client_secret!;
        requestArgs.stripeConnectPaymentIntentId = paymentIntent.id;
      }
    }

    Cookies.remove('cw.stripe.payment.session');

    this.logStep(`RESULT_E7`);
    return true;
  };

  @action confirmStripePayment_v2 = async (draftOrder: any) => {
    const stripeInstance = this.s.using_stripe_connect === 'CONNECT' ? window.stripeConnect! : window.stripe!;

    this.logStep(`REQUIRED_E1: ${ window.stripeProcessingPaymentElements }`);

    const { name, email, phone } = draftOrder.order.customer;
    const { paymentIntent, error } = await stripeInstance.confirmPayment({
      elements: window.stripeProcessingPaymentElements!,
      confirmParams: {
        return_url: `${window.location.origin}/payment/completed`,
        payment_method_data: {
          billing_details: { name, email, phone },
        },
      },
      redirect: 'if_required',
    });

    this.logStep(`RESULT_E2 ${JSON.stringify({paymentIntentId: paymentIntent?.id, RESULT_ERROR: error})}`);
    this.setPaymentItentId(paymentIntent?.id);
    if (error && (error.type === 'card_error'
      || error.type === 'validation_error'
      || error.type === 'invalid_request_error')) {
      this.order_error(undefined, `${error.message}`);
      console.log(`RESULT_E3`);
      return false;
    }

    if (error && (error.type === 'api_connection_error'
      || error.type === 'api_error'
      || error.type === 'rate_limit_error'
      || error.type === 'idempotency_error')) {
      console.log(`RESULT_E4`);
      this.order_error(undefined, 'Connection error. Please reconnect!');
      return false;
    }

    if (error) {
      console.log(`RESULT_E5`);
      this.order_error(undefined, "Unable to process payment at this time.");
      return false;
    }

    if (paymentIntent && paymentIntent.status === 'succeeded') {
      await this.checkOrderPaymentStatus(draftOrder)
    }
    // if (paymentIntent) {
    //   this.logStep(`RESULT_E6`);
    //   draftOrder.payment.status = 'success';
    //   draftOrder.payment.reference = paymentIntent.id;
    //   if (this.s.using_stripe_connect) {
    //     draftOrder.stripeConnectPaid = true;
    //     draftOrder.stripeConnectPaymentIntentClientSecret = paymentIntent.client_secret!;
    //     draftOrder.stripeConnectPaymentIntentId = paymentIntent.id;
    //   }
    // }
    //
    // Cookies.remove('cw.stripe.payment.session');

    this.logStep(`RESULT_E7`);
    return true;
  }

  @action setPayment = (method: T.Schema.Restaurant.Payments.RestaurantPaymentTypes) => {
    this.s.error = '';
    this.s.error_details = '';
    this.s.payment = method;

    this.s.using_razor_pay = method === 'razor_pay';
    this.s.using_bancontact = method === 'bancontact';
    this.s.using_applepay = method === 'checkout_apple_pay';
    this.s.using_googlepay = method === 'checkout_google_pay';
    this.s.using_stripe_digital_wallet = method === 'stripe_digital_wallet';
  };

  @action update = (data: Partial<CheckoutState>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof CheckoutState];
        if (value !== undefined) {
          // @ts-ignore
          this.s[key as keyof CheckoutState] = value;
        }
      }
    }
  };

  @action initCardConnect = async () => {
    const validateToken = await genRecaptchaToken();
		this.s.loading = true;
		const tokenizer = await this.store.api.payment_card_connect_init({ validateToken });
    if (tokenizer.outcome === 1){
      this.store.checkout.update({ error: tokenizer.message });
    }
    this.s.loading = false;
  };

  @action handleFailedOrderCreation = async (
    paymentIntentId: string,
    restaurantId: string,
    orderId?: string,
    message?: string
  ) => {
    await this.store.api.payment_handle_failed_order({
      payment_intent_id: paymentIntentId,
      restaurant_id: restaurantId,
      order_id: orderId,
      message: message,
    });
  };

  @action ablyHandleConnectionState = (stateChange: Ably.Types.ConnectionStateChange) => {
    this.ablyState = stateChange.current;
  }
  @action async validateOrderMaximumPerWindow() {
    const { store } = this
    const orderCheck = await store.api.order_check({ _id: localStorage.getItem('initial_order_id') || "",
      isPostHookRequire: false,
      validateLimitOrderReqData: {
        restaurantId: store.restaurant._id,
        // @ts-ignore
        service: store.order_config.s.service,
        // @ts-ignore
        timestamp: store.order_config.s.due === "later" ? store.order_config.s.timestamp : Date.now(),
      }
    });
    const suffix = store.order_config.s.due === "later" ? '_later' : '';
    return (orderCheck.outcome && orderCheck.message === 'exceeded_order_limit') ? orderCheck.message+suffix: false;
  }
}
