import * as React from "react";
import { styled, css, Button, CircleIcon, shadeAlternate } from "@lib/components";
import { observer, inject } from "mobx-react";
import { Box } from "./components";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { MobxComponent } from "../../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";

import { action, observable } from "mobx";

interface Props extends WithTranslation { }
interface State { active: number | null; }

const ServiceInfoWrapper = styled("div")`

`;

const ServiceInfoHeader = styled("div")`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px dashed ${({ theme }) => shadeAlternate(0.2, theme.box.background)};
  padding: 10px 0;
  &:last-child {
    margin-bottom: 0;
  }
  svg {
    color: ${({ theme }) => shadeAlternate(0.5, theme.box.background)};
  }
`;

const ServiceInfoContent = styled("div")`
  padding: 20px 0 15px 0;
`;

export interface ServiceInfoProps {
	title: string;
	content?: React.ReactNode;
	active?: boolean;
	onClick?: () => void;
}

export const ServiceInfo = (props: ServiceInfoProps) => (
	<ServiceInfoWrapper>
		<ServiceInfoHeader onClick={props.onClick}>
			<span>{props.title}</span>
			{!!props.content && (props.active ? <FaChevronUp /> : <FaChevronDown />)}
		</ServiceInfoHeader>
		{(props.content && props.active) && (
			<ServiceInfoContent>
				{props.content}
			</ServiceInfoContent>
		)}
	</ServiceInfoWrapper>
);

@inject("store") @observer
class InfoServicesClass extends MobxComponent<Props, State> {

	@observable active: number | null = null;

	@action setActive = (i: number | null) => {
		this.active = this.active === i ? null : i;
	}

	render() {
		// const { t } = useTranslation();
		// const { t } = this.injected;
		const { t } = this.props;
		const r = this.injected.store.restaurant;
		const s = r.settings.services;
		const modal = this.injected.store.modal;
		const { active } = this;
		const { services } = r.settings;
		const { pickup, delivery, dine_in, table_booking } = services;
		const zones = this.injected.store.serviceDeliveryZones;
		const deliveryZonesEnabled = zones.length > 0;
		const uom =
			this.injected.store.restaurant.settings.region.kmmile === "KM"
				? "km's"
				: "miles";
		return (
			<Box className="col">
				<CircleIcon icon={<FaUser />} />

				<h3 className="text-center font-regular m-b-6">
					{t("store.sections.info.services.title")}
				</h3>

				{pickup.enabled && (
					<ServiceInfo
						title={
							s.pickup.display_name ||
							t("constants.services.pickup")
						}
						active={active === 0}
						onClick={() => this.setActive(0)}
						content={
							!pickup.notes ? null : (
								<div className="lc-no-margin">
									<p className="font-semi-bold lhp">
										{t(
											"store.sections.info.services.notes"
										)}
									</p>
									<p className="lhp">{pickup.notes}</p>
								</div>
							)
						}
					/>
				)}

				{delivery.enabled && (
					<ServiceInfo
						title={
							s.delivery.display_name ||
							t("constants.services.delivery")
						}
						active={active === 1}
						onClick={() => this.setActive(1)}
						content={
							<div className="lc-no-margin">
								{!!delivery.notes && (
									<div className="m-b-4">
										<p className="lhp font-bold small">
											{t(
												"store.sections.info.services.notes"
											)}
										</p>
										<p className="lhp">{delivery.notes}</p>
									</div>
								)}

								{!deliveryZonesEnabled &&
									delivery.fee.type === "range" && (
										<div className="m-b-4">
											<p className="font-bold small">
												{t(
													"store.sections.info.services.delivery.ranges"
												)}
											</p>
											{ 
											['uber', 'questtag'].includes(delivery.default_delivery_provider!) &&
												//@ts-ignore
												delivery.providers[delivery.default_delivery_provider!].delivery_fee_settings && 
												//@ts-ignore
												delivery.providers[delivery.default_delivery_provider!].delivery_fee_settings.enable ?
												//@ts-ignore
												delivery.providers[delivery.default_delivery_provider!].delivery_fee_settings[delivery.providers[delivery.default_delivery_provider!].delivery_fee_settings.subsidy_type+'_ranges']
												.map(
													(range: any, i: number) => (
														<div
															className="flex-l-r-center m-t-2"
															key={i}
														>
															<p>
																{i === 0
																	? 0
																	: range.up_to}{" "}
																{uom} -{" "}
																{range.up_to} {uom}
															</p>
															<p>
																{range.cost && (range.cost === 0
																	? "FREE"
																	: t(
																			"currency",
																			{
																				value:
																					range.cost,
																			}
																	  ))}
																{range.offset &&  t(
																			"currency",
																			{
																				//@ts-ignore
																				value: delivery.default_delivery_provider! === 'uber' ? delivery.providers[delivery.default_delivery_provider!].delivery_fee_settings.default_ranges[i].cost  :
																				delivery.fee.subsidy?.subsidy_type === 'fixed_offset' ?
																				//@ts-ignore
																				delivery.providers[delivery.default_delivery_provider!].delivery_fee_settings.default_ranges[i].cost - range.offset :
																				//@ts-ignore
																				delivery.providers[delivery.default_delivery_provider!].delivery_fee_settings.default_ranges[i].cost - (delivery.providers[delivery.default_delivery_provider!].delivery_fee_settings.default_ranges[i].cost * range.offset),
																			}
																	  )}
															</p>
															{/* <p>
															{t(
																"currency",
																{
																	value: delivery.fee.ranges[i].min_order || 0,
																}
															)}
															</p> */}
														</div>
													)
												)
											:
											delivery.fee.subsidy && delivery.fee.subsidy.enable ?
												//@ts-ignore
												delivery.fee.subsidy[delivery.fee.subsidy?.subsidy_type+'_ranges'].map(
													(range: any, i: number) => (
														<div
															className="flex-l-r-center m-t-2"
															key={i}
														>
															<p>
																{i === 0
																	? 0
																	: range.up_to}{" "}
																{uom} -{" "}
																{range.up_to} {uom}
															</p>
															<p>
																{range.cost && (range.cost === 0
																	? "FREE"
																	: t(
																			"currency",
																			{
																				value:
																					range.cost,
																			}
																	  ))}
																{range.offset &&  t(
																			"currency",
																			{
																				value: delivery.fee.subsidy?.subsidy_type === 'fixed_offset' ?
																				delivery.fee.ranges[i].cost - range.offset :
																				delivery.fee.ranges[i].cost - (delivery.fee.ranges[i].cost * range.offset),
																			}
																	  )}
															</p>
															{/* <p>
															{t(
																"currency",
																{
																	value: delivery.fee.ranges[i].min_order || 0,
																}
															)}
															</p> */}
														</div>
													)
												)
											: 
											delivery.fee.ranges.map(
												(range, i) => (
													<div
														className="flex-l-r-center m-t-2"
														key={i}
													>
														<p>
															{i === 0
																? 0
																: delivery.fee
																		.ranges[
																		i - 1
																  ].up_to}{" "}
															{uom} -{" "}
															{range.up_to} {uom}
														</p>
														<p>
															{range.cost === 0
																? "FREE"
																: t(
																		"currency",
																		{
																			value:
																				range.cost,
																		}
																  )}
														</p>
													</div>
												)
											)}
										</div>
									)}

								{!deliveryZonesEnabled &&
									delivery.fee.type === "formula" && (
										<div className="m-b-4">
											<p className="lhp font-bold small">
												{t(
													"store.sections.info.services.delivery.fee"
												)}
											</p>
											<p className="lhp">
												{t(
													"store.sections.info.services.delivery.fee_varies"
												)}
											</p>
										</div>
									)}

								{!deliveryZonesEnabled &&
									delivery.fee.type === "fixed" && (
										<div className="m-b-4">
											<p className="lhp font-bold small">
												{t(
													"store.sections.info.services.delivery.fee"
												)}
											</p>
											<p className="lhp">
												{!delivery.fee.fixed_cost
													? t("phrases.free")
													: t("currency", {
															value:
																delivery.fee
																	.fixed_cost,
													  })}
											</p>
										</div>
									)}

								{deliveryZonesEnabled && (
									<div className="m-b-4">
										<p className="font-bold small">
											{t(
												"store.sections.info.services.delivery.zones"
											)}
										</p>
										{zones.map((zone, i) => (
											<div
												className="flex-l-r-center m-t-2"
												key={i}
											>
												<p>{zone.name}</p>
												<p>
													{t("currency", {
														value: zone.fee,
													})}
												</p>
											</div>
										))}
										<Button
											size="xxs"
											color="primary-inverse"
											className="m-t-2"
											onClick={() => modal.toggle("map")}
										>
											{t(
												"store.sections.info.services.delivery.view_zones"
											)}
										</Button>
									</div>
								)}

								<div className="m-b-4">
									<p className="lhp font-bold small">
										{t(
											"store.sections.info.services.delivery.min_order"
										)}
									</p>
									<p className="lhp">
										{!delivery.conditions.min_order
											? t(
													"store.sections.info.services.delivery.no_min_order"
											  )
											: t("currency", {
													value:
														delivery.conditions
															.min_order,
											  })}
									</p>
								</div>

								{!!delivery.fee.free_delivery_minimum && (
									<div className="m-b-4">
										<p className="lhp font-bold small">
											{t(
												"store.sections.info.services.delivery.free_amount"
											)}
										</p>
										<p className="lhp">
											{t("currency", {
												value:
													delivery.fee
														.free_delivery_minimum,
											})}
										</p>
									</div>
								)}
							</div>
						}
					/>
				)}

				{dine_in.enabled && (
					<ServiceInfo
						title={
							s.dine_in.display_name ||
							t("constants.services.dine_in")
						}
						active={active === 2}
						onClick={() => this.setActive(2)}
						content={
							!dine_in.notes ? null : (
								<div className="lc-no-margin">
									<p className="font-semi-bold lhp">
										{t(
											"store.sections.info.services.notes"
										)}
									</p>
									<p className="lhp">{dine_in.notes}</p>
								</div>
							)
						}
					/>
				)}

				{table_booking.enabled && (
					<ServiceInfo
						title={
							s.table_booking.display_name ||
							t("constants.services.table_booking")
						}
						active={active === 3}
						onClick={() => this.setActive(3)}
						content={
							!table_booking.notes ? null : (
								<div>
									<p className="font-semi-bold lhp">
										{t(
											"store.sections.info.services.notes"
										)}
									</p>
									<p className="lhp">{table_booking.notes}</p>
								</div>
							)
						}
					/>
				)}
			</Box>
		);
	}

}

export const InfoServices = withTranslation()(InfoServicesClass);
